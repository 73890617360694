function GetTheApp() {
    return (
        <div className="mainPage getTheApp" style={{"background": "#F9F5F6", "height": "100%", "width": "100%", "flex": 1}}>
            <div className="chevronBack"><a href="/" style={{"textDecoration": "none"}}>‹</a></div>
            <img alt="logo" src="/img/heart.png" className="heart" style={{"marginTop":"3%"}}/>
            <div className="clipSlogan" style={{"marginBottom":"20px"}}>
                <h1 className="slogan" style={{"color": "#99627A","margin":"0"}}>SwiftCrush</h1>
                <span className="connect slogan">Connect in an instant!</span>
            </div>
            <h2 className="roboto-thin" style={{"color": "black","margin":"0","font-size":"3rem"}}>You don't have our app installed...</h2>
            <h2 className="roboto-thin" style={{"color": "black","margin":"0","font-size":"2.5rem"}}>Get it now!</h2>
            <h2>
                <a href="https://play.google.com/store/apps/details?id=com.swiftcrush"><img alt="play" src="/img/google-play.png"/></a>
                <a href="https://apps.apple.com/gb/app/swiftcrush-dating/id6502757367"><img alt="appstore" src="/img/app-store.png"/></a>
            </h2>
        </div>
    )
}

export default GetTheApp;